import dynamic from "next/dynamic";
import React from "react";

export enum IconType {
  LinkedIn = "linkedin",
  Twitter = "twitter",
  Facebook = "facebook",
  Website = "website",
  GitHub = "github",
  Logo = "logo",
  LogoSm = "logo-small",
  ReactOnTheJobCourseLogo = "react-on-the-job",
  Heart = "heart",
  HeartDark = "heart-dark",
}

type IconProps = {
  className?: string;
  style?: React.CSSProperties;
  type: IconType;
};

const Icon: React.FC<IconProps> = ({ type, ...other }) => {
  const Component = dynamic(() => import(`./${type}.svg`));

  return <Component {...other} />;
};

export default Icon;
